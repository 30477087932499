<template>
  <div>
    <v-container class="text_google">
      <base-material-card
        icon="mdi-clipboard-text"
        title="ผลงาน รางวัล"
        class="px-5 py-3"
        
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                
                dense
                filled
                class="mb-2"
              />
            </v-col>          
          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="personnel_awards"
          :search="search"         
        >  
                   <template v-slot:item.award_level="{ item }">
           <span v-if="item.award_level == 'college'">ระดับวิทยาลัย</span>   
           <span v-if="item.award_level == 'provincial'">ระดับสำนักงานอาชีวศึกษาจังหวัด</span>   
           <span v-if="item.award_level == 'sector'">ระดับภาค</span>   
           <span v-if="item.award_level == 'vocational'">ระดับอาชีวศึกษา</span>   
           <span v-if="item.award_level == 'nation'">ระดับชาติ</span>   
           <span v-if="item.award_level == 'word'">ระดับโลก</span>   
          </template>

          <template v-slot:item.type_performance="{ item }">
           <span v-if="item.type_performance == 'skill'">การแข่งขันทักษะ</span>   
           <span v-if="item.type_performance == 'innovation'">การประกวดสิ่งประดิษฐ์</span>   
           <span v-if="item.type_performance == 'award'">รางวัลเชิดชูเกียรติ</span>               
          </template>       
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
          >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert>
        </v-data-table>
      </base-material-card>    
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top        
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {  
  data() {
    return {
      ApiKey: 'HRvec2021',
       loading: true,
      updateImageDialog: false,
      search: "",
      addpersonnel_awarddialog: false,
      editpersonnel_awarddialog: false,
      deletepersonnel_awarddialog: false,
      showimagedialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: "",
      },
      currentPK: null,
     headers: [      
     { text: "วิทยาลัย", align: "center", value: "college_name" }, 

        { text: "คำนำหน้าชื่อ", align: "center", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "นามสุกลุ", align: "left", value: "last_name" },      
        { text: "ผลงาน รางวัล", align: "center", value: "performance_name" },
        { text: "สถานที่", align: "left", value: "performance_location" },
        { text: "เมื่อวันที่", align: "left", value: "performance_time" },
        { text: "ระดับ", align: "center", value: "award_level" },
        { text: "ประเภท", align: "center", value: "type_performance" },   
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1,
        },
      ],
       pagination: {},      
      personnel_award: [],
      addpersonnel_award: {},
      editpersonnel_award: [],
      personnel_awards: [],
      personnel_award_sub: [],   
      branch_s: [],      
      education_level: ["ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"],
    };
  },

  async mounted() {

    let result_branch
        result_branch = await this.$http.post('branch.php', {
        ApiKey: this.ApiKey       
      })
      this.branch_s = result_branch.data       
     this.personnel_awardsQueryAll()
  },

  methods: {
    async personnel_awardsQueryAll() {     
         this.loading = true
          let userSession = JSON.parse(sessionStorage.getItem('user')) || 0   
        let result = await this.$http.post('personnel_award.php', {
          ApiKey: this.ApiKey,
          collegeVei: userSession.user_code
        }).finally(() => this.loading = false)
        this.personnel_awards = result.data     
      },
  },

  computed: {
   pages() {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0

        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      }   
  } 
};
</script>
